import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

import initialState from "./initialState"
import {MAINTENANCE_STATUS, METHOD_TYPES, SERVICE_KEY} from "../../configs/constants"
import {fetchDataAndProceed, fetchDataForToolkit} from "../helpers"
import {
  GET_MANAGE_BILLING_URL,
  GET_LATEST_VERSION,
  REDEEM_CODE_FOR_UPGRADE,
  GENERATE_SECRET_KEY,
  GET_SYSTEM_TEMPLATES_LIST,
  UPDATE_MESSAGE_TRAINING_STATUS,
  GET_MAINTENANCE_INFO,
  GET_USER_WARNING_BANNER_DATA,
  GET_STRIPE_CUSTOMER_PORTAL_DATA,
  GET_PLAN_INVOICE_PREVIEW,
  VALIDATE_PROMO_CODE,
  UPDATE_PLAN,
  FETCH_INVOICE,
  ENTERPRISE_API,
} from "./api"
import {GET_USER_CHATLOG_DETAILS} from "../chatHistorySlice/api"
import {UPDATE_FLAGGED_CHAT} from "../trainingSlice/api"
import {enqueueSnackbar} from "notistack"

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    updatePageState(state, action) {
      return {...state, ...action.payload}
    },
    updateBadgeOnSiderModule(state, action) {
      state.badgeOnSiderModule[action.payload.appPage] = action.payload.count
    },
    updatePlanUpdationData(state, action) {
      state.planUpdationPageData = action.payload
    },
    resetInvoiceData(state, action) {
      state.invoice = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSystemTemplates.fulfilled, (state, {payload}) => {
      state.systemTemplates = payload.data.templates
    })
      .addCase(getMaintenanceInfo.fulfilled, (state, {payload}) => {
        const maintenanceData = payload?.data;
        const maintenanceStatus = maintenanceData?.[0]?.status;
        const servicesUnderMaintenance = [];
        if (maintenanceData?.[0]?.services?.length > 0 && maintenanceStatus === MAINTENANCE_STATUS.IN_PROGRESS) {
          maintenanceData?.[0]?.services?.forEach((service) => {
            servicesUnderMaintenance.push(service.key);
          });
        }
        state.maintenanceInfo = {
          showMaintenanceBanner: maintenanceData?.length > 0,
          info: maintenanceData?.[0],
          servicesUnderMaintenance,
          status: maintenanceData?.[0]?.status
        }
      })
      .addCase(getUserWarningBannerData.fulfilled, (state, {payload})=>{
        state.bannerNotifications = payload.data;
      })
      .addCase(getManageBillingUrl.fulfilled, (state, {payload}) => {

      })
      .addCase(getStripeCustomerPortalData.fulfilled, (state, {payload}) => {
        state.addOnPortalInfo = payload?.data?.addOnPortalInfo
        state.subscriptionPortalInfo = payload?.data?.subscriptionPortalInfo
      })
      .addCase(getPlanInvoicePreview.fulfilled, (state, {payload}) => {
        state.invoicePreviewData = payload?.data
      })
      .addCase(validatePromoCode.fulfilled,(state,{payload}) => {
        state.promoCode = payload?.data
      })
      .addCase(fetchInvoices.fulfilled,(state,{payload})=>{
        state.invoice = {
          lastFetchedInvoiceId : payload.data.lastFetchedInvoiceId,
          hasMoreInvoice : payload.data.hasMoreInvoice,
          invoiceData : [...state.invoice.invoiceData, ...payload.data.fetchedInvoices]
        }
      })
  },
})
export const getManageBillingUrl = createAsyncThunk(
  "page/getManageBillingUrl",
  async (data = {}, helpers) => {
    const {getState} = helpers;
    const state = getState();
    const servicesUnderMaintenance = state.pageDetails.maintenanceInfo.servicesUnderMaintenance;

    if (servicesUnderMaintenance.includes(SERVICE_KEY.STRIPE_BILLING)) {
      enqueueSnackbar("This service is under maintenance. Please try again later.", 'warning');
      return;
    }
    return fetchDataAndProceed(
      helpers,
      {
        url: GET_MANAGE_BILLING_URL,
        method: METHOD_TYPES.POST,
        data: data
      },
      (err, res) => {
        if (!err && res && res.data && res.data.url) {
          window.location.href = res.data.url
        }
      }
    )
  }
)

export const redeemCodeForUpgrade = createAsyncThunk(
  "page/redeemCodeForUpgrade",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: REDEEM_CODE_FOR_UPGRADE,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
)

export const getLatestVersion = createAsyncThunk(
  "page/getLatestVersion",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_LATEST_VERSION,
        loader: false,
      },
      helpers
    )
)

export const getMaintenanceInfo = createAsyncThunk(
  "page/getMaintenanceInfo",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_MAINTENANCE_INFO,
        loader: false,
      },
      helpers
    )
)

export const getUserChatlogDetailsBySessionId = createAsyncThunk(
  "page/getUserChatlogDetailsBySessionId",
  async (payload = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_USER_CHATLOG_DETAILS.replace(":sessionId", payload.sessionId),
        data: payload.data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
)

export const generateSecretKey = createAsyncThunk(
  "page/generateSecretKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GENERATE_SECRET_KEY,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const updateSecretKey = createAsyncThunk(
  "page/updateSecretKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GENERATE_SECRET_KEY,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const deleteSecretKey = createAsyncThunk(
  "page/deleteSecretKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GENERATE_SECRET_KEY,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)

export const getSystemTemplates = createAsyncThunk(
  "dashboard/getSystemTemplates",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_SYSTEM_TEMPLATES_LIST,
        data,
      },
      helpers
    )
)

export const auditResponseByChatlogId = createAsyncThunk(
  "page/auditResponseByChatlogId",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_FLAGGED_CHAT,
        method: METHOD_TYPES.POST,
        data,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const updateMessageTrainingStatus = createAsyncThunk(
  "page/updateMessageTrainingStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_MESSAGE_TRAINING_STATUS.replace(":tenantId", data.tenantId),
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getUserWarningBannerData = createAsyncThunk(
  "page/getUserWarningBannerData",
  async (data = {}, helpers) => 
    fetchDataForToolkit(
      {
        url: GET_USER_WARNING_BANNER_DATA,
        data: data,
        loader: false,
      },
      helpers
    ) 
)

export const getStripeCustomerPortalData = createAsyncThunk(
  "page/getStripeCustomerPortalData",
  async(data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: GET_STRIPE_CUSTOMER_PORTAL_DATA,
      },
      helpers
    )
)

export const getPlanInvoicePreview = createAsyncThunk(
  "page/getPlanInvoicePreview",
  async(data={},helpers) =>
    fetchDataForToolkit(
      {
        url: GET_PLAN_INVOICE_PREVIEW,
        method: METHOD_TYPES.POST,
        data
      },
      helpers
    )
)

export const validatePromoCode = createAsyncThunk(
  "page/validatePromoCode",
  async(data={},helpers) =>
    fetchDataForToolkit(
      {
        url : VALIDATE_PROMO_CODE,
        method: METHOD_TYPES.POST,
        data
      },
      helpers
    )
)

export const updatePlan = createAsyncThunk(
  "page/updatePlan",
  async(data={},helpers) =>
    fetchDataForToolkit(
      {
        url : UPDATE_PLAN,
        method: METHOD_TYPES.POST,
        data
      },
      helpers
    )
)

export const fetchInvoices = createAsyncThunk(
  "page/fetchInvoices",
  async(data={},helpers) =>
    fetchDataForToolkit(
      {
        url : FETCH_INVOICE,
        data,
        loader : data.loader
      },
      helpers
    )
)

export const handleEnterprise = createAsyncThunk(
  'page/handleEnterprise',
  async(data={},helpers) =>
    fetchDataForToolkit(
      {
        url : ENTERPRISE_API,
        data
      },
      helpers
    )
)

export const {updatePageState, updateBadgeOnSiderModule, updatePlanUpdationData, resetInvoiceData} = pageSlice.actions
export default pageSlice.reducer
