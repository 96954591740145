export const FETCH_MY_PROFILE = "/iam/v2/users/profile"
export const GET_MANAGE_BILLING_URL = "/iam/billing/stripe/redirect-billing"
export const REDEEM_CODE_FOR_UPGRADE = "/iam/billing/redeemCode"
export const UPLOAD_IMAGE =
  "/bot/v2/bot-ui/botavatar/:tenantId?tenantId=:tenantId"
export const GET_LATEST_VERSION = "/version"
export const GET_MAINTENANCE_INFO = "/maintenance"
export const GENERATE_SECRET_KEY = "/iam/v2/users/api"
export const GET_SYSTEM_TEMPLATES_LIST = "/bot/v2/templates/systemTemplates"
export const UPDATE_MESSAGE_TRAINING_STATUS =
  "/brain/v1/conversation/flagchatlog?tenantId=:tenantId"
export const GET_USER_WARNING_BANNER_DATA = "/banner"
export const GET_STRIPE_CUSTOMER_PORTAL_DATA = "/iam/billing/stripe/customerPortal"
export const GET_PLAN_INVOICE_PREVIEW = "/iam/billing/stripe/invoicePreview"
export const VALIDATE_PROMO_CODE = "/iam/billing/stripe/validatePromocode"
export const UPDATE_PLAN = "/iam/billing/stripe/customerPortal/update"
export const FETCH_INVOICE = "/iam/billing/stripe/fetchInvoices"
export const ENTERPRISE_API = "/iam/billing/stripe/enterprise"