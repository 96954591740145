import { enqueueSnackbar } from "notistack"
import {
  LOCAL_STORAGE,
  BREAKPOINT_VALUES,
  MESSAGE_TYPES,
  CHANNELS,
  INTEGRATIONS,
  CHAT_STATE,
} from "../configs/constants"
import { AUDIO_NOTIFICATION } from "../assets"
import { cleverTap, loadCleverTapScript } from "./clevertap"
import {verifyEmailFormatUsingRegex} from './validation'
import {validateFormFields} from './form'
import {getLogicFunctionFromString} from './acl'

const notificationAudio = new Audio(AUDIO_NOTIFICATION)

const getTenantIdFromUrl = () => {
  //DO NOT EXPORT THIS FUNCTION, THIS IS FOR DATA MAPPING IN LOCAL_STORAGE ONLY
  const result = window.location.pathname.split("/")
  return result[3]
}

getTenantIdFromUrl()

export const playNotification = () => {
  try {
    notificationAudio.play()
  } catch (err) {
    log(err)
  }
}

export const uniqueId = (key) => {
  const uid = Math.random().toString(16).substring(2)
  const timestamp = new Date().getTime()
  return `OD_${key || ""}_${timestamp}_${uid}`
}

export const log = (...arg) => {
  if (process.env.NODE_ENV !== "production") console.log(...arg)
}

export const clearAllDataFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE.TOKEN)
  localStorage.removeItem(LOCAL_STORAGE.COUNTRY)
  localStorage.removeItem(LOCAL_STORAGE.IS_LOGGED_IN)
  localStorage.removeItem(LOCAL_STORAGE.USER_ID)
  localStorage.removeItem(LOCAL_STORAGE.WEBSITE_URL)
  localStorage.removeItem(LOCAL_STORAGE.DYNAMIC_POPUP_SEEN_COUNT_DATA)
  localStorage.removeItem(LOCAL_STORAGE.GUIDED_JOURNEY_STATE)
  localStorage.removeItem(LOCAL_STORAGE.USER_BANNER_DATA)
  localStorage.removeItem(LOCAL_STORAGE.HAS_CREATED_FIRST_BOT)
}

export const getDataFromLocalStorage = (key, defaultValue, withTenantId) => {
  try {
    let data = defaultValue
    if (withTenantId) {
      const tenantId = getTenantIdFromUrl()
      data = localStorage.getItem(key + "-" + tenantId)
    } else data = localStorage.getItem(key)
    return data && data !== undefined && data !== "undefined"
      ? JSON.parse(data)
      : defaultValue
  } catch (err) {
    localStorage.removeItem(key)
    window.location.reload(true)
  }
}

export const setDataInLocalStorage = (key, data, withTenantId) => {
  const jsonData = JSON.stringify(data)
  if (withTenantId) {
    const tenantId = getTenantIdFromUrl()
    localStorage.setItem(key + "-" + tenantId, jsonData)
  } else localStorage.setItem(key, jsonData)
}

export const getDeviceData = () => {
  const deviceData = {
    mobile: window.innerWidth < BREAKPOINT_VALUES.sm,
    tablet:
      window.innerWidth < BREAKPOINT_VALUES.md &&
      window.innerWidth >= BREAKPOINT_VALUES.sm,
    desktop: window.innerWidth >= BREAKPOINT_VALUES.md,
    lg: window.innerWidth >= BREAKPOINT_VALUES.lg,
    xl: window.innerWidth >= BREAKPOINT_VALUES.xl,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    orientation: window.matchMedia("(orientation:landscape)").matches
      ? "landscape"
      : "portrait",
  }
  return deviceData
}

export const fileToBase64 = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      res(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

export const getDateByType = (type) => {
  const date = new Date()

  if (type === "week") date.setDate(date.getDate() - 7)
  if (type === "month") date.setDate(date.getDate() - 30)

  return (
    date?.getFullYear() + "-" + (date?.getMonth() + 1) + "-" + date?.getDate()
  )
}

export const getMessageIdentifier = (message) => {
  if (message && message.type && message.payload)
    switch (message.type) {
      case MESSAGE_TYPES.TEXT:
        return Array.isArray(message.payload.text)
          ? message.payload.text[0]
          : message.payload.text
      // case MESSAGE_TYPES.TEXT_WITH_BUTTONS:
      // case MESSAGE_TYPES.IMAGE_WITH_BUTTONS:
      // case MESSAGE_TYPES.VIDEO: {
      //   let identifier = ""
      //   if (message.payload.title) {
      //     if (message.payload.subtitle)
      //       identifier = message.payload.title.concat(message.payload.subtitle)
      //     else identifier = message.payload.title
      //   } else if (message.payload.subtitle)
      //     identifier = message.payload.subtitle
      //   return identifier
      // }
      default:
        return ""
    }
  return ""
}

export const throttled = (func, delay) => {
  let timerId
  return (...args) => {
    if (!timerId) {
      func(...args)
      timerId = setTimeout(() => {
        timerId = null
      }, delay)
    }
  }
}

export const removeStringLastCharacter = (str, endChar) => {
  if (str && str.length > 0 && str.charAt(str.length - 1) === endChar)
    str = str.substring(0, str.length - 1)
  return str
}

export const isAppleDevice = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}

export const createChatbotUrl = (baseUrl,tenantId, additionalParams = "") => {
  let url = `${baseUrl}/?tenantId=${tenantId}&fullScreenBot=true&testBot=true&parentOrigin=${window.location.origin}&${additionalParams}`
  return url
}

export const isValidChannel = (value) => {
  return Object.values(CHANNELS).includes(value)
}

export const isValidIntegration = (value) => {
  return Object.values(INTEGRATIONS).includes(value)
}

export const isEmptyObject = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object
}

export const isKeyAlreadyExist = (key, identifier, array) => {
  const arrayofKeys = array.map((item) => item[identifier].toLowerCase())
  return arrayofKeys.includes(key.toLowerCase())
}

export const convertJSONToCSV = (array = [], fields) => {
  let csv = ""
  const replacer = (key, value) => (value === undefined ? "N/A" : value)
  csv = [
    fields.join(","),
    ...array.map((row) =>
      fields.map((fieldName) => JSON.stringify(row[fieldName], replacer))
    ),
  ].join("\r\n")
  return csv
}


export const isPaywallEnabled = (user) => {
  if (!user) return true
  else if (user?.hasLifeTimeDeal && user?.lifeTimeDealSubscription?.isElite)
    return false
  else if (user?.isSubscribed && user?.subscription?.plan_type === "Elite")
    return false
  else return true
}

export const copyToClipboard = async (text) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text)
      enqueueSnackbar("Copied Successfully")
    } catch (err) {
      log("Failed to copy: ", err)
    }
  }
}

export const getFormattedSize = (bytes, decimals = 2) => {
  if (!bytes) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFormattedNumber = (bytes=0, decimals = 0) => {
  if(bytes==0){
    return bytes
  }
  const negative = parseInt(bytes) < 0;
  if(negative) bytes *= -1;
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${negative ? "-" : ""}${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFormattedFileType = (fileType) => {
  if (!fileType) return null
  if (fileType.indexOf("image/") !== -1) return "Image"
  if (fileType === "application/pdf") return "PDF"
}

export const openUrlInNewTab = (url, target = "_blank") => {
  if (url && url.trim().length > 0) {
    let win = window.open(url, target)
    win.focus()
  }
}

export const isProductionEnv=()=>{
  return process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod';
}

export const addParameter = (param, value) =>{
  var regexp = new RegExp("(\\?|\\&)" + param + "\\=([^\\&]*)(\\&|$)");
  if (regexp.test(document.location.search))
    return (document.location.search.toString().replace(regexp, function(a, b, c, d)
    {
      return (b + param + "=" + value + d);
    }));
  else
    return (document.location.search ? document.location.search + '&' : "?") + param + "=" + value;
}
export const createPsidMapForLiveChat = (liveChats = {}, userId = "") => {
  const psidMap = {
    botPsids: [],
    inQueuePsids: [],
    myChatPsids: [],
    otherAgentChatPsids: [],
  }

  Object.keys(liveChats).forEach((id) => {
    if (liveChats[id]?.chatState === CHAT_STATE.BOT_HANDLING)
      psidMap.botPsids.push(id)
    else if (liveChats[id]?.chatState === CHAT_STATE.UNASSIGNED)
      psidMap.inQueuePsids.push(id)
    else if (
      liveChats[id]?.chatState === CHAT_STATE.AGENT_HANDLING &&
      userId &&
      liveChats[id]?.agentId === userId
    )
      psidMap.myChatPsids.push(id)
    else if (
      liveChats[id]?.chatState === CHAT_STATE.AGENT_HANDLING &&
      userId &&
      liveChats[id]?.agentId !== userId
    )
      psidMap.otherAgentChatPsids.push(id)
  })

  return psidMap
}

export const firstLetterUpperCase = (str="") => {
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }

  return arr.join(" ");
}

export const convertToTitleCase = (str='') => {
  return str.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const getDiscountedPrice = ({originalValue, value, isPercentage}) => {
  var discountedPrice = originalValue;
  if(!isPercentage) {
    discountedPrice = originalValue - value;
  } else {
    discountedPrice = (originalValue * (1-(value/100)))
  }
  return discountedPrice;
}

export const handleFileSize = (maxSizeInBytes) => {
  let cnt = 0;
  while(maxSizeInBytes > 1024 && ++cnt) {
    maxSizeInBytes = maxSizeInBytes/1024;
  }
  if(cnt === 0) return {size : maxSizeInBytes, type : 'Bytes'};
  else if(cnt === 1) return {size : maxSizeInBytes, type : 'KB'};
  else if(cnt === 2) return {size : maxSizeInBytes, type : 'MB'};
  else if(cnt === 3) return {size : maxSizeInBytes, type : 'GB'};
  else return {size : maxSizeInBytes, type : 'TB'};
}

function isEmail(str) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(str);
}

export function getRandomInt(max) {
  if(max>1)
    return Math.floor(Math.random() * max);
  return 0;
}

const getClickableLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
};

export { cleverTap, loadCleverTapScript, getLogicFunctionFromString, verifyEmailFormatUsingRegex, validateFormFields, isEmail, getClickableLink } ;
